<template>
	<div style="width: 100%">
		<v-stepper
			:value="step + 1"
			alt-labels
			width="100%"
			class="cassie-stepper"
			:class="hiddenHeader ? 'd-none' : ''"
		>
			<v-stepper-header class="stepper-header">
				<template
					v-for="(stepsStep, index) in steps"
				>
					<v-stepper-step
						:key="stepsStep.title + 'step'"
						:editable="editMode"
						:complete="stepsStep.stepComplete"
						complete-icon="mdi-check"
						:step="index + 1"
						class=" py-1"
						:edit-icon="step === index ? 'mdi-pencil' : 'mdi-check'"
						@click="stepHasChanged(index)"
					>
						{{ stepsStep.title }}
					</v-stepper-step>
					<v-divider
						v-if="index !== steps.length - 1"
						:key="stepsStep.title + 'divider'"
					/>
				</template>
			</v-stepper-header>
		</v-stepper>
	</div>
</template>

<script>
export default {
	props: {
		steps: {
			type: Array,
			required: true
		},
		step: {
			type: Number,
			required: true
		},
		editMode: {
			type: Boolean,
			default: true
		},
		hiddenHeader: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		currentStep () {
			return this.steps[this.step]
		},
		maxEditableStep () {
			return this.steps.filter(step => step.stepComplete).length
		}
	},
	methods: {
		stepHasChanged (step) {
			if (this.editMode) {
				this.$emit('update:step', step)
			}
		}
	}
}
</script>

<style lang="scss">
.cassie-stepper {
	background: none !important;
	box-shadow: none !important;
	.v-stepper__header {
		hr {
			margin-top: 16px !important;
		}
	}
}
</style>

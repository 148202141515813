<template>
	<cassie-form-builder
		mode="theme"
		:read-only="!userFullPermissions"
		:channels="mappedChannels"
		class="cassie-builder"
	/>
</template>

<script>

export default {
	props: {
		userFullPermissions: {
			type: Boolean,
			default: false
		},
		allThemes: {
			type: Array,
			default: () => []
		},
		channels: {
			type: Array,
			required: true
		},
		brandId: {
			type: Number,
			required: true
		},
		createStyleMode: Boolean
	},
	computed: {
		themes () {
			const themes = this.allThemes.filter(theme => theme.brandId === this.brandId || theme.brandId === 0)
			return themes.sort((a, b) => a.themeName.localeCompare(b.themeName))
		},
		mappedChannels () {
			const channelsWithStatements = this.channels.filter(channel => channel.statements?.length)
			const channelsAndStatements = channelsWithStatements.map(channel => ({
				value: channel.channelId,
				label: channel.channelName,
				statements: channel.statements?.map(statement => ({
					value: statement.statementID,
					label: statement.statementText
				})).sort((a, b) => a.label.localeCompare(b.label))
			})).sort((a, b) => a.label.localeCompare(b.label))
			return JSON.stringify(channelsAndStatements)
		}
	},
	created () {
		if (this.createStyleMode) {
			const builder = JSON.parse(localStorage.getItem('vueform-builder'))
			delete builder.builder.themeName
			localStorage.setItem('vueform-builder', JSON.stringify(builder))
		}
	}
}
</script>

<style>
.vfb-builder #theme\.theme ul {
	padding-left: 0;
}

.vfb-right-container #theme\.save, #theme\.load {
  background-color: #3a3950;
  border-color: #3a3950;
  color: #fff;
}
.cassie-builder {
	height: calc(100vh - 298px);
	max-height: calc(100vh - 298px);
}

#theme\.theme-multiselect-group-0, #theme\.theme-multiselect-group-1 > div {
  display: none;
}

</style>

<template>
	<SectionCard>
		<template #title>
			{{ 'Encryption Key' | useLabels }}
		</template>
		<template #title-action>
			<TextButton
				test-id="preference_page_create_encryption_key"
				@click="$emit('show-encryption-key-modal')"
			>
				Create new key
			</TextButton>
		</template>
		<template #subtitle>
			Select the encryption key that you will use to encrypt the specified fields, so that Cassie is able to decrypt the information when the link is clicked on.
		</template>
		<template #body>
			<v-row dense>
				<v-col cols="6">
					<Dropdown
						label="Encryption Key *"
						test-id="preference_page_encryption_key"
						:value.sync="encryptionKeyId"
						:items="encryptionKeys"
						rules="required"
						@change="onChange('encryptionKeyId', $event)"
					/>
				</v-col>
			</v-row>
		</template>
	</SectionCard>
</template>

<script>
import SectionCard from '../../../../../../../shared/components/section-card.vue'
import Dropdown from '../../../../../../../shared/components/dropdown.vue'
import TextButton from '../../../../../../../shared/components/text-button.vue'

export default {
	components: {
		SectionCard,
		Dropdown,
		TextButton
	},
	props: {
		encryptionKeyId: Number,
		encryptionKeys: Array
	},
	methods: {
		onChange (key, value) {
			this.$emit('update:encryption-key', key, value)
		}
	}
}
</script>

<style scoped>
.encryption-key-card__add-key-button {
	margin-right: 12px;
}

.encryption-key-card__dropdown-row {
	margin-top: 8px;
}
</style>

<template>
	<ValidationForm #default="{ handleSubmit }">
		<Modal width="700">
			<template #modal-title>
				Manage Style
			</template>
			<template #modal-content>
				<p v-if="createFromExisting && !updateMode">
					You have made alterations to the Style "{{ themeName }}". If you wish to apply these changes you must save this as a new Style or update the existing Style.
				</p>
				<v-row v-if="createMode">
					<v-col cols="8">
						<TextField
							v-model="name"
							label="Style Name"
							:rules="{ required: true, max: 20, existingStyleNames: invalidStyleNames }"
						/>
					</v-col>
				</v-row>
				<v-row v-if="updateMode">
					<v-col cols="12">
						<p>
							Are you sure you wish to update Style "{{ themeName }}"? In doing so, all Drag-and-Drop Preference Pages and Consent Collection Widgets using this style will be updated.
						</p>
					</v-col>
				</v-row>
			</template>
			<template #modal-footer>
				<template v-if="!createMode && !updateMode">
					<SecondaryActionButton @click="$emit('close')">
						Cancel
					</SecondaryActionButton>
					<v-spacer />
					<SecondaryActionButton @click="updateMode = true">
						Update Style
					</SecondaryActionButton>
					<PrimaryActionButton @click="createMode = true">
						Save New Style
					</PrimaryActionButton>
				</template>
				<template v-else>
					<SecondaryActionButton @click="cancelSaveTheme">
						Cancel
					</SecondaryActionButton>
					<PrimaryActionButton @click="handleSubmit(manageTheme)">
						{{ updateMode ? 'Save' : 'Create' }}
					</PrimaryActionButton>
				</template>
			</template>
		</Modal>
	</ValidationForm>
</template>

<script>
import ValidationForm from '../../../../../shared/components/validation-form.vue'
import Modal from '../../../../../shared/components/modal.vue'
import SecondaryActionButton from '../../../../../shared/components/secondary-action-button.vue'
import PrimaryActionButton from '../../../../../shared/components/primary-action-button.vue'
import TextField from '../../../../../shared/components/text-field.vue'
import { postTheme, putTheme } from '../../../../../shared/utils/api/form-builder.js'
import { showSnackbar } from '../../../../../shared/state/snackbar.js'

export default {
	components: {
		ValidationForm,
		Modal,
		SecondaryActionButton,
		PrimaryActionButton,
		TextField
	},
	props: {
		themeToManage: {
			type: Object,
			required: true
		}
	},
	setup () {
		return { showSnackbar }
	},
	data () {
		return {
			createMode: false,
			updateMode: false,
			name: null
		}
	},
	computed: {
		createFromExisting () {
			return this.themeToManage.createFromExisting
		},
		themeName () {
			return this.themeToManage.themeName
		},
		accessedViaNext () {
			return this.themeToManage.accessedViaNext
		},
		invalidStyleNames () {
			const existingStyleNames = Object.keys(JSON.parse(localStorage.getItem('vueform-themes')))
			return existingStyleNames
		}
	},
	created () {
		if (!this.createFromExisting) {
			this.createMode = true
		}
	},
	methods: {
		manageTheme () {
			if (this.createMode) {
				this.createTheme()
			} else if (this.updateMode) {
				this.updateTheme()
			}
		},
		async createTheme () {
			const themeProperties = JSON.parse(this.themeToManage.variables)
			delete themeProperties.themeId
			const theme = {
				brandId: this.themeToManage.brandId,
				themeName: this.name,
				json: JSON.stringify(themeProperties)
			}
			await postTheme(theme).then(response => {
				this.$emit('manage-theme', response.data.themeId, this.accessedViaNext, theme.themeName)
			})
		},
		async updateTheme () {
			const themeProperties = JSON.parse(this.themeToManage.variables)
			delete themeProperties.themeId
			const theme = {
				themeName: this.themeToManage.themeName,
				brandId: this.themeToManage.brandId,
				json: JSON.stringify(themeProperties)
			}
			await putTheme(this.themeToManage.themeId, theme).then(() => {
				this.$emit('manage-theme', this.themeToManage.themeId, this.accessedViaNext, theme.themeName)
			})
		},
		cancelSaveTheme () {
			this.name = null
			if (!this.createFromExisting) {
				this.$emit('close')
			} else {
				this.createMode = false
				this.updateMode = false
			}
		}
	}
}
</script>
